import {ref} from 'vue'
import fnExpose from '@lib/expose';
import eventbus from '@app/eventbus'
import clsModelBase from '@cls/clsModelBase'
import {numeric} from '@lib/numeric'
import feature from "@app/features";

import noty from '@shared/lib/noty'

/**
 * Base class which is intended to be a base class for entity classes. 
 * This implementation is added after 'migration' to rightsgroups / actions.
 * Actions are granted by the navigation class. 
 * 
 * An entity class is an entity which is used for an implementation.
 * For example, an invoice class which controls editing and managing an invoice.
 * 
 * This class offers some utilities to handle e.g. paraphing. 
 * Note that it is not required to use those utilities.
 * 
 * Also, access, modify and remove rights can be specified. 
 * The canModify, canAccess and canRemove mmethods reflect the availabillity of those rights.  
 * 
 */

// Usage: 
// import { clsModel, fnCreate } from '@cls/clsModel'
// import {invoice as api} from '@app/api'
//
// class clsInvoice extends clsModel {
//    constructor() {
//        super({
//          api: api, 
//          eventSaved: eventbus.invoice.saved,     
//          eventRemoved: eventbus.invoice.removed, 
//        })
//    }    
//    
// }
// export default fnCreate(clsInvoice, 'clsInvoice');



// Usage: 
//    import clsActionEntityBase from '@/lib/composition/clsActionEntityBase'
// 
//    class clsInvoice extends clsEntityBase {
//
//        constructor() {
//            super({
//                useParaphs: true,
//                id_module: constants.modules.purchaseInvoices,
//                accessRights: 'unit.open',
//                modifyRights: 'unit.modify',
//                removeRights: 'unit.remove',
//                createRights: 'unit.create',
//                apiLoad: api.units.load,               // Required when load is not overwritten
//                apiRemove: api.tariff.remove,           // Required when remove is not overwritten
//                apiSave: api.units.save,               // Required when save is not overwritten
//                eventSaved: eventbus.unit.saved,      // Required when save is not overwritten
//                eventRemoved: eventbus.unit.removed,  // Required when remove is not overwritten
//
//            })
//        }
//    
//        ... your class implementation 
//    }
//

let entityStore = {};

class clsModel extends clsModelBase {

    _isFilling = false;

    id = null;
    noteCount = null;
    flag = null;
    attachmentCount = null;
    id_optimit_type = null;
    fillable = null;

    // We want an easy way to keep track on the state of the changes. 
    // The state of the model is stored so that the user can be warned when closing data without changing.
    initialState = null;    // initial state is used to check if it is dirty

    /**
     * Is a fill action in progress?
     * Note that this does not work when the fill method is overwritten.
     */
    get isFilling() {
        return this._isFilling;
    }
    set isFilling(v) {
        this._isFilling = v;
    }

    /**
     * Get the current state, which is basicly just a stringification fo the json representation
     * The purpose of this function is being able to compare the actual status of the object with 
     * the status immediately after loading/filling. 
     * The following issues need to be resolved: 
     *    
     *    - numeric fields can be loaded or defaulted as e.g. 10 which is interpreted as an integer. 
     *      After manipulation or loading, they may be a float and represented as 10.00
     *      RESOLVE: convert al Number fields to rounded fields (2 decimals)
     * 
     *    - date fields can be stored in the database as "2023-11-22 00:00:00". 
     *      after manipulation, the value may be:  "2023-11-22"
     *      RESOLVE: replace all expressions like "NNNN-NN-NN" with "NNNN-NN-NN 00:00:00"
     * 
     *    - bool fields may be represented as true or 1 or false of 0
     *      RESOLVE: replace all bool fields by 1 or 0. 
     * 
     *    NOTE: when overriding toJSON, also commit to those practises!!!! 
     * 
     */
    getCurrentState() {
        var json = this.toJSON();
        
        for(var key in json) {
            json[key] = this.adjustValueForState(json[key], key)
        }

        return JSON.stringify(json).replaceAll('"', "").replaceAll('false', '0').replaceAll('true', '1');
    }

    adjustValueForState(val, name) {
        if(!isNaN(Number(val))) {
            return `${numeric.fmt(Number(val), 2)}`;
        }
        if (typeof(val) == 'boolean') {
            return val ? 1 : 0;
        }
        if (Array.isArray(val) && val.length) {
            let arr = [];
            val.forEach( (item) => arr.push(this.adjustValueForState(item, name)))
            return arr;
        }
        if (typeof(val) == "object" && val !== null) {
            let clone = {}; // do not modify the object itself
            for (var k in val ) {
                clone[k] = this.adjustValueForState(val[k], k);                
            }
            return clone;
        }
        if (typeof(val) == "string") {
            const found = val.match(/\d\d\d\d-\d\d-\d\d$/);
            if (found && found.length) {
                return val + " 00:00:00"
            }
        }
        return val;
    }



    /**
     * set the initial state. Do this to compare it later with the current state to check whether the model is dirty.
     */
    setInitialState() {
        this.initialState = this.getCurrentState();
    }

    /**
     * Is any of the fields modified?
     * @returns 
     */
    get isModified() {
        if (!feature.canDirtyTrack) {
            return false;
        }
        var state = this.getCurrentState();
        return state != this.initialState;
    }
    
    // Is this a new record?
    get isNew() {
        return !Number(this.id)
    }

    api = null;             // Required when the load is not overwritten

    // Constructor.
    // Usage: 
    //   super( {
    //   })
    //
    // Note: when useParaphs is true, this does not mean that parahs MUST be used.
    //       it means that all paraph functionallity is enabled, which means that e.g. event 
    //       registrations are set appropriately.
    // 
    // 
    constructor(config) {
        super(config);
        config = config || {};
        this.id_optimit_type = config.id_optimit_type; // Required when working with notes / attachments.
        this.api = config.api;                    // The api to execute server operations on. 
                                                  // When api is not specified, or when the api does not implement e.g. api.load, the load method must be overwritten when used.
        this.fillable=config.fillable;
        this.registerEvents();

    }

    // Override to register for events.
    registerEvents() {
    }
    
    /**
     * Helper to format an array of values in a checkable array. 
     * The use case for this is when items must be added as a local list which must be manipulatable via e.g. a datatable. 
     * For example, the employee list in a rightsgroup. 
     *     In this case, the list of employees can be manipulated by checking the items. 
     *     In order for this to work, the properties must be in the items before they are made responsive. 
     * Usage:: 
     *  fil(data) {
     *      this.items = this.checkable(items);
     *  }
     * 
     * @param {*} items 
     */
    checkable(items) {
        if (Array.isArray(items)) {
            items.forEach( (item) => {
                if (item.checked === undefined) {
                    item.checked = false;
                } 
            })
        }
        else if (items) { // single value
            if (items.checked === undefined) {
                items.checked = false;
            }         
        }
        return items||[];
    }

    /**
     * Convert this object to JSON
     * Override when required. 
     * Example implementation: 
     *           return this.propsToJSON(["id","name","rights"])
     * @returns 
     */
    toJSON() {
        if (!this.fillable) {
            console.error("clsModel - toJSON must be overridden when fillable is not specified");
            return {};    
        }
        return this.propsToJSON(['id', 'flag', ...this.fillable]);
    }

    /**
     * Override this method to execute after save action
     * @param {*} resultData 
     */
    onAfterSave(resultData) {

    }
    /**
     * Override this method to handle default notification or to not notify
     * @param {*} resultData 
     */
    onSaveNotification(resultData, msg) {
        msg = msg || "De gegevens zijn opgeslagen"
        noty.snack.success(msg);
    }

    /**
     * Override to set the rules for validation. 
     */
    // get rules() {
    //     return {
    //         // Example:
    //         // name: [ v => !!this.isLoading || !!this.isDataLoading || !!v || "Naam is verplicht" ],
    //         // size: [ v => !!this.isLoading || !!this.isDataLoading || !!v || "Afmeting is verplicht" ],
    //     };
    // }

    /**
     * Override and implement if you want to execute validations before save. 
     * Note that this method is not called by us, but must be called by e.g. the containing dialog.
     * 
     * Example: 
     *      async checkBeforeSave(data) {
     *           await noty.confirm("Weet u zeker dat u dit nu wilt doen en niet op een later tijdstip?.");
     *      }
     * 
     * A more elaborate example would be to execute checks on the server and add confirmation accordingly.
     * Example: 
     *     async checkBeforeSave(data) {
     *        await api.getWarnings(data);     
     *     }

     * 
     * @returns 
     */
    async checkBeforeSave(data) {
        return true; 
    }

    /**
     * send the saved event.
     */
    sendSavedEvent(data) {
        let sData = data ||this;
        if (this.modelName) {
            eventbus.model.saved(this.modelName, sData);
        }
    }

    /**
     * Send an event which indicates that the statistics for the current model must be refreshed.
     */
    sendStatsRefreshEvent() {        
        eventbus.model.stats.refresh(this.modelName);
    }


    /**
     * The default api save implementation just calls save on the api.
     * Override this method for example to call another save method.
     */
    apiSave(data) {
        return this.api.save(data);
    }

    /**
     * Does the save action return data which we must re-fill in our model?
     * By default true. Override when not.
     */
    fillAfterSave() {
        return true;
    }

    /**
     * Default implementation for saving an entity
     */
    async save() {
        let data = this.toJSON();
        if (!this.api?.save) {
            console.error("clsModel - api.save must be implemented");
            return null;
        }
        try {
            this.isLoading = true;
            await this.checkBeforeSave(data);
            let result = await this.apiSave(data);
            this.sendSavedEvent(result.data);
            this.onSaveNotification(result.data);
            this.onAfterSave(result.data);
            if (this.fillAfterSave()) {
                this.fill(result.data);
            }
            return result;          
        }
        finally {
            this.isLoading = false;
        }
    }
    
    /**
     * Default implementatio for removing the entity. 
     * Note that we just execute the remove action.
     * The backend ensured data authorization, the frontend may ask confirmations et all.
     */
     remove() {
        if (!this.api?.remove) {
            console.error("clsModel - api.remove must be implemented");
            return null;
        }

        var id = this.id;            
        var self = this;
        return self.api.remove(id).then( (data) => {
            if (self.modelName) {
                eventbus.model.removed(self.modelName, [id]);
                return id;  
            }
        });          
    };

    /**
     * The default api load implementation just loads the data via the api with the id.
     * Override this method for example to load the data via other parameters (e.g. id_employee, id_tariff). 
     * extraData is an optional extra parameters. 
     * Typical use is that it indicates whether a specific action is to be executed.
     * For example, create a copy or create a credit.
     */
    apiLoad(id, params, extraData) {
        if (!this.api?.load) {
            console.error("clsModel - api.load must be specified");
            return null;
        }
        return this.api.load(id, params);
    }

    /**
     * override the implementation when required 
     */
    async onAfterLoad(data, params, extraData) {
        // Do nothing
    }
    /**
     * Default implementation for laoding the entity via the api and fill the fields.
     * Params are specified when the model is loaded from e.g. a datatable.
     */
    async load(id, params, extraData) {
        var self = this;
        this.fill({});
        return this.withDataLoading( () => {
            return self.apiLoad(id, params, extraData)
              .then( ({data}) => {
                self.fill(data);
                self.onAfterLoad(data, params, extraData)
//                // The data is loaded. This is our initial state.
//                self.setInitialState(); 
            })
        })    
    }


    /**
     * Reload the model data with the id we already have
     */
    reload() {
        if (!this.id) {
            console.error("Reload without id. Canceled.");
            return;
        }
        return this.load(this.id);
    }
    /**
     * Default implementation for laoding the entity via the api and fill the fields.
     * Params are specified when the model is loaded from e.g. a datatable.
     */
    copy(id, params) {
        if (!this.api?.copy) {
            console.error("clsModel - api.copy must be specified");
            return null;
        }
        var self = this;
        this.fill();
        // Our initial state is empty. 
        // After loading the data, we are dirty by default.
        self.setInitialState(); 
        return this.withDataLoading( () => {
            return self.api.copy(id)
              .then( ({data}) => {
                self.fill(data);                
            })
        })    
    };

    /**
     * Override to create specific implementations. 
     */
    async doCreate(defaults) {
        defaults = defaults || {};
        for (var key in defaults) {
            this[key] = defaults[key];
        }
    }

    /**
     * Create an entity. Basicly, just clear the data. Override doCreate to implement additional logic.
     */
    async create(defaults) {
        defaults = defaults ||{}; // may or may not be used.
        var self = this;
        return this.withDataLoading( async () => {
            self.fill({});
            await self.doCreate(defaults);

            // The data is created. This is our initial state.
            self.setInitialState(); 

        })
    }


    /**
     * Overwrite and call super to implement for entity. 
     * 
     * @param {*} data 
     */
    fill(data, ignoreInitialState) {
        this._isFilling = true;

        data = data ||{};
        this.id = data.id;
        this.flag = data.flag;
        this.noteCount = data.noteCount;
        this.attachmentCount = data.attachmentCount;

        // Default not provided data to null.
        (this.fillable||[]).forEach( (field) => {
            if (undefined == data[field]) {
                data[field] = null;
            }
        })
        var self = this;
        (this.fillable||[]).forEach( (field) => {
            // If the field has a fillData method, use that.
            // Note the we can not use a method like 'fill' as that is already defined for 
            // arrays types
            if (self[field] && typeof(self[field].fillData) == 'function') {
                self[field].fillData(data[field]);
            } else {
                // Otherwise, just assign the value.
                self[field] = data[field];
            }
        })

        if (!ignoreInitialState) {
            // The data is loaded. This is our initial state.
            self.setInitialState(); 
        }

        this._isFilling = false;


        return data;
    }
}

let exposed = false;

function fnCreate(cls, name) {
    // 
    // We re-use one object for a tariff. We just don't need another one. 
    // There is no use case (yet) for having a new instance for every invocation.
    // This way we don't have to deal with all kind of cleaning up (e.g. eventbus handlers).
    // - note that 
    // 
    return function useObject(bCreateNew) {
        if (bCreateNew) { // ignore the entity store, just return a new instance.
            let instance = ref(new cls());
            return instance.value;
        }
        exposed = exposed || fnExpose(entityStore, 'store', 'entity')
        if (!entityStore[name]) {
            entityStore[name] = ref(new cls());        
        }
        return entityStore[name].value;  
    }    
}


export { clsModel, fnCreate }
