<template>
        <v-card class="bg-card mb-2">
            <v-card-title>
                <v-row dense>
                    <v-col sm="6" xl="3">
                        <TextField v-if="model.tend_use_paragraphs" :disabled="model.isOpenOrLater" :model='model' placeholder="Paragraaf titel" :rules='model.rules.mandatory' v-model='paragraph.thp_name'></TextField>
                        <TextField v-else :disabled="model.isOpenOrLater" max-length="255" placeholder="Offertetitel" :model="model" :rules="model.rules.tend_name" v-model="model.tend_name"></TextField>
                    </v-col>
                    <v-col class="inline-flex v-center h-right">                
                        <span v-if="!paragraph.thp_calculate" class="text-small text-italic mr-4 text-error">Wordt niet meegenomen in offerte-totalen.</span>
                        
                        <ActionMenu icon="settings" :close-on-content-click="false"> 
                            <ActionButton iconButtonOutlined  icon="settings"></ActionButton>
                            <template v-slot:dropmenu>
                            <v-card >
                                <v-card-text>
                                    <FormRow class-label="w-min-8" col-label="9" col-value-right  label="Gebruik hoofdstukken">            
                                        <Checkbox :disabled="model.isOpenOrLater" class="d-inline-block width-auto" switcher v-model="model.tend_use_paragraphs"></Checkbox>
                                    </FormRow>
                                    <FormRow class-label="w-min-8" v-if="model.tend_use_paragraphs" col-label="9" col-value-right  label="Neem hoofdstuk mee in offerte totalen ">            
                                        <Checkbox :disabled="model.isOpenOrLater" class="d-inline-block width-auto" switcher v-model="paragraph.thp_calculate"></Checkbox>
                                    </FormRow>
                                    <FormRow class-label="w-min-8" v-if="model.tend_use_paragraphs" col-label="9" col-value-right  label="Begin hoofdstuk op nieuwe pagina">            
                                        <Checkbox :disabled="model.isOpenOrLater" class="d-inline-block width-auto" switcher v-model="paragraph.thp_new_page"></Checkbox>
                                    </FormRow>
                                    <FormRow class-label="w-min-8" v-else col-label="9" col-value-right  label="Begin offerte op nieuwe pagina">            
                                        <Checkbox :disabled="model.isOpenOrLater" class="d-inline-block width-auto" switcher v-model="paragraph.thp_new_page"></Checkbox>
                                    </FormRow>
                                    <v-divider></v-divider>
                                    <FormRow class-label="" col-label="1" col-value-right  label="">            
                                        <ActionButton :disabled="model.isOpenOrLater" class="mt-3" sub action="tender.modify" dusk="btn-clone-chapter" :model="model" icon="clone" @click='()=>cloneParagraph(paragraph)'>Dupliceer dit hoofdstuk</ActionButton>
                                    </FormRow>
                                </v-card-text>
                            </v-card>
                            </template>
                        </ActionMenu>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="px-0 pt-0 pb-0">
                <LocalTable :disabled="model.isOpenOrLater" :model="paragraph" modelLinesProp='lines' editable ref="lines" :canDrag="!model.isOpenOrLater" no-hover dense :dt="tblLines">
                    <template v-slot:row="{ index, item, dt, columnHeaders }">
                        <ProductLine @expand="()=>onExpand(item)" ref="line" v-if="item.tphp_type!='text'" :model="model" :key="index" :item="item" :dt="dt" :columnHeaders="columnHeaders">
                            <template v-slot:menu="{ item }">
                                <ActionButton ml dusk='btn-line-remove' :disabled="model.isOpenOrLater" :model="model" icon-button icon="remove" 
                                    action="tender.modify" @click="() => paragraph.removeLine(item)">
                                </ActionButton>
                            </template>
                        </ProductLine>
                        <TextLine ref="line" v-else :model="model" :key="index" :item="item" :dt="dt" :columnHeaders="columnHeaders">
                            <template v-slot:menu="{ item }">
                                <ActionButton ml dusk='btn-line-remove' :disabled="model.isOpenOrLater" :model="model" icon-button icon="remove" 
                                    action="tender.modify" @click="() => paragraph.removeLine(item)">
                                </ActionButton>
                            </template>
                        </TextLine>
                    </template>


                    <template v-slot:tfoot="{/*dt, hasCmd, */columnHeaders}">
                        <tfoot >
                        <tr>
                            
                            <td class="pt-4 pb-4 pl-4" :colspan="columnHeaders.length-3">
                                <span class="position-absolute">
                                    <ActionButton sub action="tender.modify" dusk="btn-add-line" :model="model" :disabled="model.isOpenOrLater" icon="add" @click='()=>addLine()'>Nieuwe regel</ActionButton>
                                    <ActionButton sub dusk="btn-add-text-line" :model="model" dropdown :disabled="model.isOpenOrLater"  ml icon='text'>
                                        Tekstregel
                                        <template v-slot:dropdown>
                                            <ActionMenuItem  no-icon dusk="btn-add-line-empty-text" :model="model" action='tender.modify' no-execute :disabled='model.isOpenOrLater' @click='(evt)=>addEmptyText(evt)'>Lege tekstregel</ActionMenuItem>
                                            <ActionMenuItem  no-icon dusk="btn-add-line-standard-text" :model="model" action='tender.modify' no-execute :disabled='model.isOpenOrLater' @click='(evt)=>addStandardText(evt)'>Standaard tekstregel</ActionMenuItem>
                                        </template>
                                    </ActionButton>
                                    <ActionButton sub action="tender.modify" dusk="btn-add-product" :model="model" ml :disabled="model.isOpenOrLater" icon="product" @click='()=>addProductLine()'>Artikel</ActionButton>
                                </span>
                                <span class="min-h-16"></span>
                            </td>
                            <td class="text-right text-small pr-3"></td>
                            <td class="text-right text-small text-bold pr-3 no-wrap" :class="{'text-error': paragraph.totalProfit <0, 'text-success': paragraph.totalProfit >0}">    
                            </td>
                            <td colspan="2" class="text-right text-bold pr-3 pt-1" >    
                                <span class="mt-2">
                                    <AmountText v-model='paragraph.total' :currency='model.currency'/>     <br>                                                           
                                </span>
                                <span class="mt-1 inline-block">
                                    <span class="mr-2">Marge:</span> 
                                    <span :class="{'text-error': paragraph.totalProfit <0, 'text-success': paragraph.totalProfit >0}"> 
                                        <AmountText v-model='paragraph.totalProfit' :currency='model.currency'/>
                                    </span>
                                </span>
                            </td>
                        </tr>
                        
                        </tfoot>
                    </template>

                    <template v-slot:header.amount_excl="{datatable}">
                        <span>
                            <span v-if="model.useAmountsExclVat">Totaal Excl. BTW</span>
                            <span v-else class="text-error">Totaal Incl. BTW</span>
                        </span>
                        <span v-if="1 == 100">
                            <span class="text-primary" @click="model.useAmountsExclVat=!model.useAmountsExclVat">
                                <v-menu offset-y bottom>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="cursor-pointer">
                                            <span class="mr-1"><Icon small color="primary" type='edit'></Icon></span>
                                            <span v-if="model.useAmountsExclVat">Totaal Excl. BTW</span>
                                            <span v-else>Totaal Incl. BTW</span>
                                        </span>
                                        </template>
                                        <v-list dense>
                                            <ActionMenuItem :disabled="model.isOpenOrLater" no-icon action="tender.modify"  @click="()=>model.useAmountsExclVat=true">Exclusief BTW</ActionMenuItem> 
                                            <ActionMenuItem :disabled="model.isOpenOrLater" no-icon action="tender.modify"  @click="()=>model.useAmountsExclVat=false">Inclusief BTW</ActionMenuItem> 
                                        </v-list>
                                </v-menu>
                            </span>
                        </span>
                        
                    </template>

                </LocalTable>
            </v-card-text>
        </v-card>
</template>


<script setup>

    import TextField from '@controls/input/TextField'
    import Checkbox from "@controls/check/Checkbox.vue";
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import IconMenu from "@controls/menu/IconMenu.vue";
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import Icon from '@controls/icons/Icon'
    import {computed, ref, vue, nextTick} from 'vue'

    import ProductLine from "./ProductLine"
    import TextLine from "./TextLine"
    import ActionMenu from '@controls/menu/ActionMenu.vue'
    import AmountText from '@controls/text/Amount.vue'
    import FormRow from '@controls/forms/FormRow'
    import noty from '@shared/lib/noty'
    import dlg from '@app/dlg'

    const props = defineProps({
        model: {
            type: [Object]
        },
        paragraph: {
            type: [Object]
        },
    });

    let headers = [

        { canhide: false, canmove: false, type: 'cmd'}, 
        { canhide: false, canmove: false, type: 'drag',                     class:'w-012'}, 
        { text: 'Aantal',                                    value: 'tphp_amount',              class:'w-2 text-right pr-3'},
        { text: 'Eenheid', configText: 'Eenheid',            value: 'id_unity',                 class:'w-min-3 w-3 pl-3'},
        { text: 'Omschrijving',                              value: 'tphp_pd_name',             class:'w-min-3 w-max-10 pl-3'   },
        { visible: true,  text: 'Artikel',                   value: 'id_product',               class:'w-min-3 w-5 pl-3'},
        { visible: false, text: 'Fabrikant',                 value: 'sup_name',                 class:'w-min-3 w-4 pl-3', cellClass: 'pl-3'},
        { visible: false, text: 'Fabrikantcode',             value: 'pd_supplier_code',         class:'w-min-3 w-3', cellClass: 'pl-3'},
        { visible: false, text: 'Fabrikantnaam',             value: 'pd_supplier_name',         class:'w-min-3 w-4 pl-3', cellClass: 'pl-3'},
        { visible: false, text: 'Ean',                       value: 'ean',                      class:'w-min-3 w-4 pl-3', cellClass: 'pl-3'},
        { canhide: false, text: 'Inkoopprijs',               value: 'tphp_purchase_price',      class:'w-min-3 w-3 text-right pr-3'},
        { canhide: false, text: 'Verkoopprijs',              value: 'tphp_sales_price',         class:'w-min-3 w-3 text-right pr-3'},
        { canhide: true, canmove:false, text: 'Marge',       value: 'marge',                    cellClass:"text-right pr-3", class:'w-min-2 w-2 text-right pr-3'},
        { canhide: false, text: "BTW",                       value: "id_vat",                   class:"w-min-3 w-3 pl-3"}, 
        { canhide: false, text: "Totaal Excl. BTW",          value: "amount_excl",              cellClass:"text-right pr-3", class:"w-3 text-right pr-3"}, 
    ];

    let tblLines = ref(new clsLocalTable("tender.lines", headers, {
        noDataText: "Er zijn nog geen regels toegevoegd",
        noSort: true,
        model: props.paragraph, 
        modelLinesProp: 'lines'        
    }));

    window.invl = tblLines

    // ---> REDO: See invoices for better implementation.    
    const line = ref(); 
    const lines = ref(); 
        function setLineFocus(index) {            
            console.log('setLineFocus', index)
            let lastChild = line?.value?.$parent?.$children;
            if (!lastChild || !lastChild.length || lastChild.length < index ) {
                console.log('nope 1')
                return;
            }
            window.lch = lastChild;
            // Don't know why. The first case is for product lines. The 2nd is for text lines.
            if (lastChild[index]?.$refs?.rfocus?.length) {
                lastChild = lastChild[index]?.$refs?.rfocus[0];
            } else if(lastChild[index]?.$refs?.rfocus.$el) {
                lastChild = lastChild[index]?.$refs?.rfocus;
            } else {
                console.log("nope 2")
                return;
            }
            lastChild = lastChild.$el;
            if (!lastChild || !lastChild.getElementsByClassName) {
                console.log('nope 3')
                return;
            }
            let targetElement = lastChild.getElementsByClassName('ProseMirror');
            if (!targetElement) {
                console.log('nope 4a')
                return;
            }
            if (!targetElement || !targetElement[0]) {
             console.log('nope 4b')
                return;
            }

            if (!targetElement || !targetElement.length || !targetElement[0].focus) {
                console.log('nope 4c')
                return;
            }
            targetElement[0].focus();
            console.log('done')
        }

    function addLine() {
        let index = props.paragraph.addLine();
        window.lref = line;
        window.linesref = lines;
        // Ideally, we would like to use nextTick. 
        // However, nextTick just does not seem to do the trick here. Probably need vue 3.0 i.s.o. 2.7
        // For now, just use setTimout instead.
        // nextTick( () => setLineFocus(index));
        setTimeout( ()=>setLineFocus(index), 0)
    }
    function addEmptyText() {
        let index = props.paragraph.addTextLine();

        setTimeout( ()=>setLineFocus(index), 0)
    }
    async function addStandardText() {
        try { 
            let selected = await dlg.promised.open("select_standardtext", {multiselect:false})
            if (!selected || !selected.length) {
                return;
            }
            props.paragraph.addTextLine(selected[0]);
        } catch (e) { return; }  // canceled
    }
    async function addProductLine() {
        try {
            var fnMultiSelectCallback = function(selected) {
                if (!selected || !selected.length) {
                    return;
                }
                props.model.addProductLine(selected[0], props.paragraph);                
            }

            let selected = await dlg.promised.open("select_product", {multiselect:false, fnMultiSelectCallback: fnMultiSelectCallback, filter: {type: 'rp'}}) // rp: recipes and products
            if (!selected || !selected.length) {
                return;
            }
            props.model.addProductLine(selected[0], props.paragraph);
        } catch (e) { return; }
    }

    async function cloneParagraph(paragraph) {
        try {
            await noty.confirm("Weet u zeker dat u dit hoofdstuk wilt dupliceren?");
        }
        catch (e) { return; } // Canceled
        props.model.cloneParagraph(paragraph)
    }

    async function onExpand(line) {
        if (! await noty.tryConfirm('Wanneer u doorgaat wordt deze regel vervangen door regels met alle artikelen waaruit het recept is opgebouwd.')) {
            return;
        }
        props.model.expandRecipe(props.paragraph, line)
    }

</script>
