<template>
    <Dialog :dialog="dlg">
        <template v-slot:toolbar-left>
            
            <ToolbarField label="Status">
                <StatusChip type="pi" :status="model.pi_status">{{model.statusRep}}</StatusChip>

            </ToolbarField>

            <ToolbarField class="ml-8" label="Relatie">
                <span class="text-primary">{{model.rel_name}}</span>
            </ToolbarField>

        </template>
        <template v-slot:toolbar-right>

            <ActionButton ml no-model-on-purpose dusk='btn-reject' error action="purchaseinvoice.reject" :disabled="model.isDataLoading || model.showStatusMessage"  @click='()=>onReject()'></ActionButton>
            <ActionButton ml no-model-on-purpose dusk="btn-accept" success main action="purchaseinvoice.accept" :disabled="model.isDataLoading||model.isStatusAtLeastAccepted || model.showStatusMessage"  @click='()=>onAccept()'></ActionButton>
        </template>
        <template v-slot:toolbar-menu>
            <ActionMenuItem no-model-on-purpose action='purchaseinvoice.backtonew' dusk='btn-back-to-new' v-if="model.isStatusAtLeastAccepted||model.isStatusRejected"  icon="back" no-execute @click="()=>setStatusNew()"></ActionMenuItem>
            <ActionMenuItem action="purchaseinvoice_history.open" @click="appDlg.open('purchaseinvoice_history', {id: model.id})"></ActionMenuItem>            
        </template>        

        <template v-slot:tabs-left>
            <v-tabs dense v-model="dlg.tab">        
                <v-tab dusk="tab-header">Gegevens</v-tab>
                <v-tab v-if="feature.canProject" dusk="tab-project">Projectverdeling</v-tab>
            </v-tabs>
        </template>        

        <v-tabs-items class="relation-tabs" v-model="dlg.tab">                
            <v-tab-item class="purchase-invoice-main-editor" transition="fade-transition" reverse-transition="fade-transition">                        
                <v-form ref="formHeader" lazy-validation>
                    <v-row dense >
                        <v-col sm="6" lg="6" xl="5" nclass="col-5">
                            <TabPurchaseInvoicePdf :model="model"> </TabPurchaseInvoicePdf>
                        </v-col>
                        <v-col class="pl-2">
                            <PurchaseInvoiceStatusMessage v-if="model.showStatusMessage" :model="model"></PurchaseInvoiceStatusMessage>
                            <TabPurchaseInvoiceHeader v-if="model.showInputData" :model="model"/>                            
                            <TabPurchaseInvoiceVatLines v-if="model.showInputData" class="d-sm-none d-lg-block mt-4" :model="model"/>
                        </v-col>
                    </v-row>
                    <TabPurchaseInvoiceVatLines v-if="model.showInputData" class="d-sm-block d-lg-none mt-4" :model="model"/>
                </v-form>
            </v-tab-item>
            <v-tab-item v-if="feature.canProject" transition="fade-transition" reverse-transition="fade-transition">                        
                <v-form ref="formProject" lazy-validation>
                    <TabPurchaseInvoiceDetailLines :model="model"/>
                </v-form>
            </v-tab-item>
        </v-tabs-items>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/purchaseinvoice'
    import Dialog from '@shared/ui/dialogs/ActionDialogModal'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { ref, watch } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import noty from '@lib/noty'

    import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    import TabPurchaseInvoiceHeader from './TabPurchaseInvoiceHeader'
    import TabPurchaseInvoiceVatLines from './TabPurchaseInvoiceVatLines'
    import TabPurchaseInvoiceDetailLines from './TabPurchaseInvoiceDetailLines'
    import TabPurchaseInvoicePdf from './TabPurchaseInvoicePdf'
    import PurchaseInvoiceStatusMessage from './PurchaseInvoiceStatusMessage'

    import feature from "@app/features";

    import StatusChip from "@controls/widgets/StatusChip.vue";
    import appDlg from '@app/dlg'

    const formHeader = ref(null); 
    const formProject = ref(null); 


    let model = useModel();
    let dlg = ref(
        new clsDialog("purchaseinvoice", "Inkoopfactuur", model, [formHeader, formProject], 
        {            
            keepOpen: true,
            noteType: 'purchaseinvoice_note',
            flagType: 'purchaseinvoice_flag',
            browseAction: "purchaseinvoice",
            canBrowse: true,
            xsm:12,xlg:10,xxl:8
        }
    )).value;  //TODO

    async function onAccept() {
        if (!model.canModify) {
            var isFormValid = await dlg.validate();
            if (!isFormValid) {
                noty.alert("De factuur kan niet worden goedgekeurd omdat niet alle benodigde velden zijn ingevuld.");
                return;
            }
        }
        await noty.confirm("U gaat deze factuur goedkeuren. Wanneer u een boekhoudkoppeling heeft ingesteld wordt de factuur komende nacht naar de boekhouding gestuurd.<br><br>Goedgekeurde inkoopfacturen kunt u terugvinden via de 'Zoeken' functie.", {title: 'Wilt u doorgaan?'})
        if (await dlg.onSave()) {
            await model.accept();        
            dlg.browseNext();
        }
    }
    async function onReject() {
        var reason = await appDlg.promised.open('purchaseinvoice_reject', {id: 1 /* don't care */})           
        await model.reject(reason);
    }
    async function setStatusNew() {
        await noty.confirm("U gaat deze factuur terugzetten naar status 'nieuw'. Hierna kunt u de factuur weer aanpassen en moet hij opnieuw worden geaccepteerd.", {title: 'Wilt u doorgaan?'})
        await model.setStatusNew();
    }
    ////////////////////////////
    //
    // PDF Viewer
    //
    // 


    // 
    window.pidlg   = dlg;
</script>
