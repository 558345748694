<template>
        <v-card class="bg-card pt-0 mb-4">
            <v-card-text class="px-0 pb-0 pt-0">
                <LocalTable :model="model.detaillines" modelLinesProp='lines' no-hover dense :dt="tblDetailLines" editable>
                    <template v-slot:menu="{ item }">
                        <ActionMenuItem :model="model" dusk='btn-remove'  icon="remove" no-execute action="purchaseinvoice.modify" @click="() => model.removeDetailLine(item)">Verwijder regel</ActionMenuItem>
                    </template>
                    <template v-slot:item.number="{ item }">
                        <NumberField decimal dusk='number' no-border :model='model' :rules='model.rules.number' v-model='item.number' ></NumberField>
                    </template>
                    <template v-slot:item.id_unit="{ item }">
                        <UnitCombo dusk='id_unit' no-border :model='model' :rules='model.rules.id_unit' v-model='item.id_unit'></UnitCombo>
                    </template>
                    <template v-slot:item.description="{ item }">
                        <TextField max-length="45" dusk='description'  no-border :model='model' :rules='model.rules.description' v-model='item.description'></TextField>
                    </template>
                    <template v-slot:item.id_product="{ item }">
                        <ProductPicker dusk='id_product'  no-border @select="(p)=>onProductSelect(p,item)" scfieldMap="productFieldMap" :model='model' :valueModel="item" :rules='model.rules.id_product' v-model='item.id_product'></ProductPicker>
                    </template>
                    <template v-slot:item.id_project="{ item }">
                        <ProjectPicker dusk='id_project' no-border :filter="{'selectfor':'purchaseinvoice'}" :fieldMap="projectFieldMap"  :model='model' :valueModel="item" :rules='model.rules.id_project' v-model='item.id_project'></ProjectPicker>
                    </template>
                    <template v-slot:item.id_cost_type="{ item }">
                        <CostTypeCombo dusk='id_cost_type' no-border :model='model' :rules='model.rules.id_cost_type' v-model='item.id_cost_type'></CostTypeCombo>
                    </template>
                    <template v-slot:item.amount="{ item }">
                        <NumberField dusk='amount' no-border  amount :model='model' :rules='model.rules.amount' v-model='item.amount' :currency='model.crnc_code'>
                            <template v-slot:prepend-inner>
                                <span class="cursor-pointer xslot-show-on-hover" v-if="(!model.disabled)&&model.missing_detail_amount" @click="()=>model.calcDetailLineAmount(item)"><Icon small type="calculator"></Icon></span>
                            </template>
                        </NumberField>
                    </template>
                    <template v-slot:item.total="{ item }">
                        <AmountText dusk='total' v-model='item.total' :currency='model.currency'/>    
                    </template>

                    <template v-slot:item.cmd-remove="{ item }">
                        
                        <ActionButton :model="model" dusk="btn-remove-line" ml icon-button xcolor="white" icon="remove" action="purchaseinvoice.modify" 
                            @click="() => model.removeDetailLine(item)">
                        </ActionButton>

                    </template>

                    <template v-slot:tfoot>
                        <tfoot >
                        <tr>
                            <td class="pt-4 pb-6 pl-4" :colspan="tblDetailLines.visibleHeaderCount-3">
                                    <v-row dense>
                                        <v-col>
                                            <span class="text-left position-absolute">
                                                <ActionButton sub action="purchaseinvoice.modify" dusk="btn-add-line" icon="add" @click='()=>onAddDetailLine()'>Nieuwe regel</ActionButton>
                                                <ActionButton sub action="purchaseinvoice.modify" dusk="btn-add-line-product" ml  icon="product" @click='()=>onAddProductLine()'>Artikel</ActionButton>
                                            </span>
                                        </v-col>
                                        <v-col class="text-right">
                                            <span class="text-right pr-3">
                                                <span v-if="model.missing_detail_amount" class="mr-3">
                                                    Nog te verdelen:
                                                </span>
                                                <span v-if="model.missing_detail_amount" class="text-bold text-error" >
                                                    <AmountText dusk="missing_detail_amount" v-model="model.missing_detail_amount" :currency="model.crnc_code"></AmountText>
                                                </span>
                                            </span>
                                        </v-col>
                                    </v-row>
                            </td>
                            <td colspan="1">                                
                            </td>
                            <td class="text-right  text-bold " colspan="1">                                
                                <span>
                                    <AmountText v-model="model.detaillines.total" :currency="model.crnc_code"></AmountText>
                                </span>
                            </td>
                            <td colspan="1">                                
                            </td>
                        </tr>
                        </tfoot>
                    </template>

                </LocalTable>

            </v-card-text>
        </v-card>
</template>


<script setup>

    import TextField from '@controls/input/TextField'
    import UnitCombo from '@controls/combo/Unit'
    import ProjectPicker from '@controls/picker/Project'
    import ProductPicker from '@controls/picker/Product'
    import CostTypeCombo from '@controls/combo/CostType'
    import AmountText from '@controls/text/Amount.vue'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import {purchaseinvoice as settings} from '@app/settings'
    import NumberField from '@controls/input/NumberField'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import eventbus from '@app/eventbus'
    import {computed, ref} from 'vue'
    import Icon from '@controls/icons/Icon'
    import dlg from '@app/dlg'
    import string from '@lib/string'

    // Our fields which come from projects. We want them overridden when a project is selected.
    let projectFieldMap = {pro_reference: 'pro_reference', pro_number: 'pro_number'};
    // Take over the following fields from the corresponding properties in the selected item: 
    const productFieldMap = {
    //  selectfield:              invoiceline field
        pd_code:                  'pd_code',
        pd_supplier_code:         'pd_supplier_code',
        pd_purchase_price:        'amount', 
        id_unity:                 'id_unit',
        sup_name:                 'sup_name',
        pd_name:                  'description',
    };
    function onProductSelect(product, line) {
        line.pd_code =           product.pd_code;
        line.pd_supplier_code =  product.pd_supplier_code;         
        line.amount =            product.pd_purchase_price;
        line.id_unit =           product.id_unity;
        line.sup_name =          product.sup_name; 
        line.description =       string.left(product.pd_name,45);    
    }

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    let headers = [

        { type: "cmd"}, 
        { class: "w-2 text-right pr-3",             value: "number",         text: "Aantal",           }, 
        { class: "w-4 pl-3",        value: "id_unit",        text: "Ehd",              }, 
        { class: "w-6 pl-3",        value: "description",    text: "Omschrijving",     }, 
        { class: "w-6 pl-3",        value: "id_product",     text: "Artikel",          }, 
        { class: "w-4 pl-1", cellClass:"px-1",        value: "sup_name",       text: "Fabrikant",    visible: false}, 
        { class: "w-6 pl-3",        value: "id_project",     text: "Project",          }, 
        { class: "w-4 pl-1", cellClass:"px-1",        value: "pro_reference",  text: "Referentie",   visible: false}, 
        { class: "w-4 pl-3",        value: "id_cost_type",   text: "Kostentype",       }, 
        { canhide: false, class: "w-4 text-right pr-3", "value": "amount",         text: "Prijs",        }, 
        { canhide: false, class: "text-right w-4 ", cellClass:'text-right',       "value": "total",          text: "Totaal Excl. BTW", cellClass: 'text-right'}, 
        { canhide: false, cellClass:"pl-2", class: "cmd pl-2", "text": "",        value: "cmd-remove"}, 
    ];

/*
    `created_by` int(10) DEFAULT NULL,
    `updated_by` int(10) DEFAULT NULL,
    `deleted_by` int(10) DEFAULT NULL,
    `deleted_at` timestamp NULL,
    `created_at` timestamp NULL DEFAULT current_timestamp(),
    `updated_at` timestamp NULL DEFAULT current_timestamp() ON UPDATE current_timestamp(),
*/

    let tblDetailLines = ref(new clsLocalTable("dt_purchaseinvoice_detaillines", headers, {
        noDataText: "Er zijn nog geen detail regels toegevoegd",
        model: props.model.detaillines, 
        modelLinesProp: "lines"    
    }))

    /**
     * Select a product, then add a line for it.
     */
    async function onAddProductLine() {
        let product = null;
        try {
            product = await dlg.promised.open('select_product', {multiselect:false})
        } catch(e) {
            return;
        }
        if (!product || !product.length) {
            return;
        }
        console.log('On promisedselected', product)
        props.model.addDetailProductLine(product[0]);
    }

    /**
     * Add a aline to the detail lines
     */
    function onAddDetailLine() {
        props.model.addDetailLine();
    }

    window.tblDL = tblDetailLines;
</script>
