<template>

    <Picker 
        :disabled="disabled"
        :skeleton="skeleton"
        :rules="rules"
        :noClearable="noClearable"
        :placeholder="placeholder"
        :model="model"         
        :valueModel="valueModel"
        :filter="filter"
        :idField="idField" 
        :repField="repField"
        selectIdField="id" 
        selectRepField="pro_number" 
        :fieldMap="fieldMap"
        dialogName="select_project"
        :dialogOptions="{noSubItemSelect:noChapterSelect}"
        :noBorder="noBorder"
        :propagateClear="propagateClear"
        :childRepField="childRepField"
        :childIdField="childIdField"
        childSeparator="-H"
        @select="onSelect"
    >
    <template v-slot:append>
        <slot name="append"></slot>

    </template>

    </Picker>

</template>

<script setup>

    // import Picker from '@controls/picker/Product'
    // <ProductPicker :model="model" :rules="model.rules.id_product" v-model="model.id_product"></ProductPicker>
    const emit = defineEmits(['select'])
    function onSelect(v) { emit('select', v); }
    const props = defineProps({

        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        noBorder: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        placeholder: {
            type: [String],
            default: 'Project'
        },
        model: {
            type: [Object]
        },
        valueModel: {
            type: [Object]
        },
        // Mandatory. The name of the field in the valueModel or model which is the id of this picker field
        idField: {
            type: [String],
            default: 'id_project'            
        },
        // Mandatory. The name of the field in the valueModel or model which is the representation of this picker field
        repField: {
            type: [String],
            default: 'pro_number'            
        },
        // Optional. The id in the select list which we take over on select
        selectIdField: {
            type: [String],
            default: "id"
        },        
        // Optional. The name of a field which is shown as an appendix to the representation
        // It can be used to show extra information. E.g. "Project 12345.4" where .4 is the chapter which is shown as an 
        // appendix.
        childRepField: {
            type: [String],
            default: "ch_rep"
        },
        // Optional. The id of a child field
        // E.g. instead of a project, a project with an associated chapter can be chosen. The child is a chapter in this case
        // appendix.
        childIdField: {
            type: [String],
            default: "id_project_chapter"
        },
        // Optional. The repField in the select list which we take over on select. Defaults to the property 'repField'
        selectRepField: {
            type: [String],
        },
        // When provided, the fieldmap contains fields which must be taken over from a selected item.
        // Those are sibling fields. For example, for a project picker, the reference may be displayed in a separate field or column.
        fieldMap: {
            type: [Object]
        },
        // When the field is filled, and then cleared, should the new values (empty value) be propagated to
        // any depending field? (defined in the fieldmap)
        propagateClear: {                    
            type: [Boolean]
        },
        // The default filter used for browsing. 
        // Example, for selecting a project in a purchase invoice, only projects which are in phase where it is allowed to 
        // book purchase invoices should be found. The same for sales invoices. 
        filter: {
            type: [Object]
        },
        noChapterSelect: {
            type: [Boolean],
            default: false
        },
    })
    
    import Picker from '@controls/picker/Picker'

</script>
